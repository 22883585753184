<template>
  <SubLayout parentName="SecurityMonitoring" :navData="navData"></SubLayout>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SubLayout from "@/layout/SubLayout";
import * as SecurityMonitoring from "@/router/modules/SecurityMonitoring";

export default {
  // `name`与路由保持一致
  name: "SecurityMonitoring",
  components: {
    SubLayout,
  },

  computed: {
    // ...mapState(['cachePages']),
    navData() {
      return Object.values(SecurityMonitoring);
    },
  },

  methods: {
    ...mapMutations(["setCachePages"]),
  },
  beforeDestroy() {
    // 从store `cachePages` 清除所有子页面
    // let { cachePages, navData } = this;
    // cachePages = cachePages
    //   .filter(menu => !navData.some(nav => nav.name === menu.name));
    // this.setCachePages(cachePages);
  },
};
</script>
